<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
        <van-nav-bar title="资讯详情" />
      </div> -->
    <!-- 内容 -->
    <!-- <van-tabs
      @click="onClickOnlyOne1"
      color="#02408f"
      title-active-color="#02408f"
      style="margin-bottom: 10px"
    >
      <van-tab
        v-for="item in List"
        :key="item.key"
        :name="item.name"
        :title="item.name"
      >
      </van-tab>
    </van-tabs> -->

    <div class="box">
      <div class="tit">基本信息</div>
      <div class="heng">
        <div class="li">求助人</div>
        <div class="li">{{ list.Name }}</div>
      </div>
      <div class="heng">
        <div class="li">求助时间</div>
        <div class="li">{{ list.ResortTime | Time }}</div>
      </div>
      <div class="heng">
        <div class="li">联系电话</div>
        <div class="li">{{ list.TouchTel }}</div>
      </div>
      <div class="heng">
        <div class="li">求助地点</div>
        <div class="li">
          {{ list.Address
          }}<van-icon
            @click="gotominiProgram"
            name="http://alycommunity.anluoyun.cn/lc-lcxms/2023/12/18cf1703-375.png"
          />
        </div>
      </div>
      <div class="heng">
        <div class="li">事件简要描述</div>
        <div class="li" style="width: 65%; text-align: right">
          {{ list.Describe }}
        </div>
      </div>
    </div>

    <div class="box box1" v-if="list.Imgs">
      <div v-if="list.Imgs" class="essayTitle" style="font-size: 15px">
        <div class="tit" style="margin-bottom: 6px">现场照片</div>
        <!-- vertical-align: top; -->

        <img
          style="
            width: 96px;
            height: 92px;
            border-radius: 4px;
            margin-right: 10px;
            margin-top: 15px;
          "
          v-for="(item, index) in arr1"
          :key="index"
          :src="item"
          alt=""
          @click="thematic(arr1)"
        />
      </div>
    </div>

    <div class="footer">
      <van-button type="default" @click="delet">删除</van-button>
      <van-button v-if="list.Status == 1" type="info" @click="jieshou"
        >接收</van-button
      >
      <van-button
        v-if="list.Status == 2"
        type="info"
        @click="chuli('/shop/chuli/' + list.ResortId)"
        >处理</van-button
      >
    </div>
  </div>
</template>
  <script>
import { ImagePreview } from "vant";
import {
  GetResort,
  WxGetTeMemberPage,
  RecResort,
  DeleteResort,
} from "@/api/RealInfo";
import Pdfh5 from "pdfh5";
import { getwgToken } from "@/utils/auth";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      riKind: "3",
      loading: false, //加载
      CateList: [
        {
          cate: 3,
          Name: "正式队员",
        },
        {
          cate: 1,
          Name: "志愿者",
        },
        {
          cate: 2,
          Name: "预备队员",
        },
      ],
      List: [
        {
          key: 1,
          name: "队伍",
        },
        {
          key: 2,
          name: "队员",
        },
      ],
      Show: true,
      list: {},
      list1: [],
      pdf: "",
      pdfh5: "",
      listfrom: {}, //查询传参
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      hidden: true, //没有更多数据了
      arr1: [],
    };
  },
  filters: {
    Time(val) {
      return val.replace("T", " ");
    },
  },
  methods: {
    delet() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定要删除该条记录吗?",
      })
        .then(() => {
          // on confirm
          DeleteResort({
            accToken: getwgToken(),
            ResortId: this.list.ResortId,
          }).then((res) => {
            if (res.data.code == 0) {
              Dialog.alert({
                message: "删除成功!",
              }).then(() => {
                // on close
                this.$router.go(-1);
              });
            } else {
              Dialog.alert({
                message: "删除失败," + res.data.msg,
              }).then(() => {
                // on close
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    gotominiProgram(details) {
      if (this.list.Coordinate == "") {
        Toast.fail("暂无位置信息");
      } else {
        this.nameCc = {
          Navigation: "导航",
          Coordinate: this.list.Coordinate,
        };
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url:
              "../../../public/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }
    },
    onClickOnlyOne1(name, title) {
      console.log(name);
      if (name == "队伍") {
        this.getRealInfo();
        this.Show = true;
      }
      if (name == "队员") {
        this.getList();
        this.Show = false;
      }
    },
    jieshou() {
      Dialog.confirm({
        title: "温馨提示",
        message: "您确定要接收该条记录吗?",
      })
        .then(() => {
          // on confirm
          RecResort({
            accToken: getwgToken(),
            ResortId: this.list.ResortId,
          }).then((res) => {
            if (res.data.code == 0) {
              Dialog.alert({
                message: "接收成功!",
              }).then(() => {
                // on close
                this.$router.go(-1);
              });
            } else {
              Dialog.alert({
                message: "接收失败," + res.data.msg,
              }).then(() => {
                // on close
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    chuli(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../../../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    thematic(e) {
      ImagePreview({
        images: e, // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: false, // 是否显示轮播指示器
        loop: false, // 不设置为false,首图和尾部图不能正常加载
      });
    },
    // 富文本图片内容 点击放大
    imageEnlargement(e) {
      if (e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false, // 不设置为false,首图和尾部图不能正常加载
        });
      }
    },

    // 获取分页列表
    getList() {
      this.listfrom.cate = this.riKind;
      this.listfrom.name = this.search;
      WxGetTeMemberPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list1 = [];
            } else {
              this.list1 = res.data.data;
              // this.list = {};
              this.total = res.data.count;
              this.finished = true;
              for (var i = 0; i < this.list1.length; i++) {
                var item = this.list1[i].IssTime;
                item = this.dataFormat(item);
                this.list1[i].IssTime = item;
                // if (this.list[i].ShopImgUrl != "rejected") {
                //   this.list[i].ShopImgUrl =
                //     "https://lyxnyapi.dexian.ren//upload/images/2022/7/b93cef0f-689.png";
                // }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 获取资讯详情 https://api.qxzhqm.cn//upload/files/2022/3/20211217-老城区民营经济综合服务平台（PC端）(1)(1).docx
    getRealInfo() {
      this.listfrom.accToken = getwgToken();

      this.listfrom.resortId = this.$route.params.id;
      GetResort(this.listfrom).then((res) => {
        this.list = res.data.data;
        console.log(this.list, "this.list");
        var arr = this.list.Imgs.split(",");
        console.log(arr);
        var arr1 = [];
        arr.forEach((i) => {
          if (i.length > 0) {
            arr1.push("http://alycommunity.anluoyun.cn" + i);
          }
        });
        console.log(arr1, "arr1");
        this.arr1 = arr1;
        //    this.list.Imgs = 'http://alycommunity.anluoyun.cn/lc-lcxms/'
      });
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    activated() {
      var _this = this;
      _this.uuid = _this.$route.query.uuid;
      var shareUrl = global.BASE_SHARE_URL + "grade?uuid=" + _this.uuid;
      this.$wxShare.updateWxShareConfig({
        title: "标题",
        desc: "简介",
        link: shareUrl,
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
  <style scoped>
.child-view {
  min-height: 100vh;
  background: #f7f7f7;
  overflow: hidden;
}
.essay .essayContent img {
  display: block;
  width: 100%;
}
.pageNum-num {
  display: none;
}
.m-pdf img {
  width: 100%;
}

.footer {
  width: 100%;
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 0px 54px 0px rgba(80, 100, 253, 0.09);
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
}

.footer button {
  width: 45.4%;
  border-radius: 4px;
}
.footer button:first-child {
  margin-right: 11px;
  margin-left: 4px;
}
.footer button:last-child {
  margin-right: 4px;
}

.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}

/deep/ .van-button--info {
  background-color: #02408f;
  border: 1px solid #02408f;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

/deep/ .van-button--default {
  border: 1px solid #999999;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1b2024;
}
.clicked {
  background-color: #fff;
  border: 1px solid #02408f;
  color: #02408f;
}
.shop {
  background: #f8f8f8;
  min-height: 100vh;
  padding-top: 10px;
}
.van-card__thumb {
  margin-right: 11px;
  width: 65.5px;
  height: 86.5px;
}
.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  /* box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%); */
  /* background: rgb(246, 243, 243); */
}
.centertime {
  font-size: 14px !important;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/ .van-card {
  border-radius: 8px;
}

.centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1b2024;
  padding-top: 4px;
}

/deep/ .van-icon__image {
  width: 1.2em;
  height: 1.2em;
}

/deep/ .van-icon {
  vertical-align: middle;
  margin-left: 5px;
}

/deep/ .van-card__footer {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.essay {
  width: 93%;
  margin: 30px auto;
  padding-bottom: 50px;
}

/deep/ .van-tabs__nav--card {
  border-radius: 14px;
  border: none;
}

/deep/ .van-tabs__nav--card .van-tab .van-tab--active {
  /* border-radius: 9px; */
  /* border-right: none; */
}

/deep/ .van-tabs__nav--card .van-tab {
  border-right: none;
  border-radius: 14px;
}

.desc {
  color: #1b2024;
  font-size: 14px;
}

.box {
  width: 95%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px 17px 24px 16px;
  margin-top: 12px;
}

.heng {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 22px;
}

.li:last-child {
  color: #323234;
}

.box1 {
  margin-bottom: 100px;
}
</style>
  